<template>
    <div class="h-full">
        <v-container>
            <v-row
                no-gutters
                class="my-10"
            >
                <v-col
                    cols="12"
                    xl="8"
                    offset-xl="2"
                >
                    <router-link to="/assets">
                        <h2 class="mb-5">
                            Assets
                        </h2>
                    </router-link>
                    <router-view />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    name: 'AdminDashboard',
};
</script>

<style lang="scss" scoped></style>
